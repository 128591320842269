import {useEffect, useState} from 'react';
import './Home.css';
import axios from 'axios';

// Components
import TableCoins from "./Components/TableCoins/TableCoins";


function Home() {

  const [coins, setCoins] = useState([]);
  const [search, setSearch] = useState('');
  

  const getData = async () => {
    const res = await axios.get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=eur&order=market_cap_desc&per_page=100&page=1&sparkline=false")
    setCoins(res.data);
  }

  useEffect(() => {
    getData();
  }, [])

  return (
    <div className='container'>
      <div className='row'>
        <input type="text" placeholder='Buscar una moneda' className='form-control bg-dark text-light border-0 mt-4 text-center' onChange={element => setSearch(element.target.value)}/>
        <TableCoins coins={coins} search={search}/>
      </div>
    </div>
  );
}

export default Home;
