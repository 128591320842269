import React from 'react';
import CoinRow from '../CoinRow/CoinRow';

const options = ['Position', 'Coin', 'Price', 'Price Change'];

const TableCoins = ({coins, search}) => {

    const buscador = coins.filter((coin) => 
        coin.name.toLowerCase().includes(search.toLowerCase()) | coin.symbol.toLowerCase().includes(search.toLowerCase())
    );

  return (
    <div>
        <table className='table table-dark mt-4 table-hover'>
            <thead>
                <tr>
                    {
                        options.map(title => (
                            <td key={title}>{title}</td>
                        ))
                    }
                </tr>
            </thead>
            <tbody>
                {buscador.map((coin, index) => (
                    <CoinRow coin={coin} key={index} index={index+1}/>
                ))}
            </tbody>
        </table>
    </div>
    
  )
}

export default TableCoins