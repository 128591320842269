import Home from "./app/pages/Home/Home";


function App() {
  return (
    <div id="root">
      <Home />
    </div>
  );
}

export default App;
