import React from 'react';

import axios from 'axios';


const CoinRow = ({coin, index}) => {

    async function sendMessagePriceBitcoin() {
           
        const webhookUrl = 'https://hooks.slack.com/services/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';
    
        const data = {
            "text": "Eh, el precio del bitcoin es: "+coin.current_price+"",
        }
    
        let res = await axios.post(webhookUrl, JSON.stringify(data), {
            withCredentials: false,
            transformRequest: [(data, headers) => {
                delete headers.post["Content-Type"]
                return data
            }]
        })
    
        if (res.status === 200) {
             console.log("Message Sent!")
    
        } else {
            console.log("There was an error.  Please try again later.")
        }
    
    }

    const valores = window.location.search;

    //Creamos la instancia
    const urlParams = new URLSearchParams(valores);

    //Accedemos a los valores
    var producto = urlParams.get('send');

    if (producto === 'yes') {
        if (coin.symbol === "btc" && coin.current_price <= 90000) { //30000
            sendMessagePriceBitcoin();
        }
    }    
    

    return (
        <tr>
            <td>{index}</td>
            <td>
                <img src={coin.image} alt={coin.name} loading="lazy" style={{width: '4%'}} className="img-fluid me-4"/>
                <span>{coin.name}</span>
                <span className='ms-3 text-muted text-uppercase'>{coin.symbol}</span>
            </td>
            <td>{coin.current_price}€</td>
            <td className={coin.price_change_percentage_24h > 0 ? "text-success" : "text-danger"}>{coin.price_change_percentage_24h}%</td>
        </tr>
    )
}

export default CoinRow